<template>
  <div class="webWrap">
    <div class="banner">
      <img src="static/lvya/banner/01.png" alt="">
      <button @click="toLink()">预约演示</button>
    </div>
    <IndexCont1></IndexCont1>
    <IndexCont2></IndexCont2>
    <IndexCont3></IndexCont3>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="360px"
      class="tc_div">
      <div>
        <img src="static/lvya/schoolFzpt/index/yyys4.png" alt="">
        <p>微信扫码获取更多产品资料</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import IndexCont1 from './coms/IndexCont1.vue'
import IndexCont2 from './coms/IndexCont2.vue'
import IndexCont3 from './coms/IndexCont3.vue'
export default {
  components: { IndexCont1, IndexCont2, IndexCont3 },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    toLink(path) {
      this.dialogVisible = true
      // window.open('http://eos.lvya.org/', '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
  .banner {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    img{
      width: 100%;
    }
    button{
      position: absolute;
      top: 60%;
      left: 20%;
      width: 110px;
      height: 35px;
      background: #0DC579;
      box-shadow: 0px 7px 21px 0px rgba(0,182,107,0.4100);
      border-radius: 5px;
      color: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }
  }
  .tc_div{
    text-align: center;
    p{
      margin-top: 20px;
    }
  }
</style>
