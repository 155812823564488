<template>
  <div class="sh">
    <div class="webCont">
      <div class="indexTitle">
        <p>学校能获得什么</p>
        <span></span>
      </div>
    </div>
    <div class="cont">
      <div class="webCont">
        <img src="static/lvya/schoolFzpt/index/sh-img1.png" alt="" style="margin-left: -84px;" class="wow animate__slideInLeft">
        <div class="text wow animate__slideInRight">
          <p class="t">学校发展目标及时诊断与改进</p>
          <p class="b">围绕学校办学目标的诊断报告</p>
          <p class="b">支持决策、服务决策、监管教育现状</p>
          <p class="b">改进教育治理，科学评价助力学校高质量发展</p>
        </div>
      </div>
    </div>
    <div class="cont" style="background: #fff;">
      <div class="webCont">
        <div class="text wow animate__fadeInLeftBig">
          <p class="t">学生立体成绩单</p>
          <p class="b">兼顾学生对学科知识的掌握以及通用能力培养</p>
          <p class="b">引导学生培养综合能力，培养创新思维</p>
          <p class="b">以通用能力量规和表现性评价为桥梁，实现学教评一体化</p>
        </div>
        <img src="static/lvya/schoolFzpt/index/sh-img2.png" alt="" class="wow animate__fadeInRightBig" style="margin-right: -50px;"></img>
      </div>
    </div>
    <div class="cont">
      <div class="webCont">
        <img src="static/lvya/schoolFzpt/index/sh-img3.png" alt="" style="margin-left: -84px;" class="wow animate__lightSpeedInLeft">
        <div class="text wow animate__lightSpeedInRight">
          <p class="t">教师能力发展全方位剖析</p>
          <p class="b">基于学生培养目标对教师发展持续赋能</p>
          <p class="b">围绕教师发展能力指标，汇聚教师业务数据</p>
          <p class="b">从过程与结果全面剖析教师能力薄弱点</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  },
}
</script>
<style lang="less">
  .sh{
    background: #F9F9F9;
    .cont{
      display: flex;
      padding: 50px 0;
      .webCont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .t{
          font-size: 32px;
          font-weight: 500;
          color: #222222;
          margin-bottom: 40px;
        }
        .b{
          font-size: 17px;
          font-weight: 400;
          color: #696969;
          background: url(../../../../../public/static/lvya/schoolFzpt/index/sh-circle.png) no-repeat left center;
          padding-left: 30px;
          // line-height: 1;
          margin-bottom: 40px;
        }
      }
    }
  }
</style>
