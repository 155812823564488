<template>
  <div class="cpln">
    <div class="webCont">
      <div class="indexTitle">
        <p>产品理念</p>
        <span></span>
      </div>
      <div class="cont">
        <div class="blueBox">
          <div class="text text1 wow animate__fadeInDown" data-wow-delay="0.15s">为谁培养人？</div>
          <div class="text text2 wow animate__fadeInDown" data-wow-delay="0.65s">培养什么样的人？<br/>有理想 + 有本领 + 有担当</div>
          <div class="textBor text3 wow animate__fadeInDown" data-wow-delay="1.15s">
            什么样的结构和路径<br/>
            （比如课程结构）<br/>
            对我们实现培养目标<br/>
            是必不可少的？<br/>
          </div>
          <div class="textBor text4 wow animate__fadeInDown" data-wow-delay="1.65s">
            什么样的行动和资源<br/>
            (比如教师能力)<br/>
            对我们完成产出<br/>
            是必不可少的？
          </div>
          <div class="text text5 wow animate__fadeInDown" data-wow-delay="1.35s">如何培养人?</div>
          <div class="circle wow animate__rollIn">使命</div>
          <img src="static/lvya/schoolFzpt/index/cpln-r.png" alt="" class="wow animate__fadeInLeft" data-wow-delay="0.25s">
          <div class="circle wow animate__rollIn" data-wow-delay="0.5s">培养目标</div>
          <img src="static/lvya/schoolFzpt/index/cpln-r.png" alt="" class="wow animate__fadeInLeft" data-wow-delay="0.75s">
          <div class="circle wow animate__rollIn" data-wow-delay="1s">产出</div>
          <img src="static/lvya/schoolFzpt/index/cpln-r.png" class="wow animate__fadeInLeft" alt="" data-wow-delay="1.25s">
          <div class="circle wow animate__rollIn" data-wow-delay="1.5s">投入</div>
          <img src="static/lvya/schoolFzpt/index/cpln-jt1.png" alt="" class="bigJt wow animate__bounceInLeft" data-wow-delay="2s">
        </div>
        <div class="orangeBox">
          <div class="circle wow animate__rollInR" data-wow-delay="3.5s">学校发展</div>
          <img src="static/lvya/schoolFzpt/index/cpln-l.png" alt="" class="wow animate__fadeInRight" data-wow-delay="3.25s">
          <div class="circle wow animate__rollInR" data-wow-delay="3s">学生发展</div>
          <img src="static/lvya/schoolFzpt/index/cpln-l.png" alt="" class="wow animate__fadeInRight" data-wow-delay="2.75s">
          <div class="circle wow animate__rollInR" data-wow-delay="2.5s">课程建设</div>
          <img src="static/lvya/schoolFzpt/index/cpln-l.png" alt="" class="wow animate__fadeInRight" data-wow-delay="2.25s">
          <div class="circle wow animate__rollInR" data-wow-delay="2s">教师发展</div>
          <img src="static/lvya/schoolFzpt/index/cpln-jt2.png" alt="" class="bigJt wow animate__bounceInRight" data-wow-delay="3.5s">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  },
}
</script>
<style lang="less">
  .cpln{
    background: url(../../../../../public/static/lvya/schoolFzpt/index/cpln-bg.png) no-repeat bottom center;
    background-size: 100%;
    // padding-bottom: 100px;
    .cont{
      padding: 230px 0;
      height: 435px;
      background: url(../../../../../public/static/lvya/schoolFzpt/index/cpln-img.png) no-repeat center;
      background-size: 1185px;
      box-sizing: content-box;
      position: relative;
      .blueBox{
        width: 840px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px auto 0;
        position: relative;
        .circle{
          width: 123px;
          height: 123px;
          background: url(../../../../../public/static/lvya/schoolFzpt/index/cpln-circle1.png) no-repeat center;
          color: #fff;
          text-align: center;
          line-height: 123px;
        }
        .bigJt{
          position: absolute;
          left: 50%;
          margin-left: -321px;
          bottom: -37px;
        }
      }
      .orangeBox{
        width: 840px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 153px auto 0;
        position: relative;
        .circle{
          width: 123px;
          height: 123px;
          background: url(../../../../../public/static/lvya/schoolFzpt/index/cpln-circle2.png) no-repeat center;
          color: #fff;
          text-align: center;
          line-height: 123px;
        }
        .bigJt{
          position: absolute;
          left: 50%;
          margin-left: -321px;
          top: -37px;
        }
      }
      .text{
        position: absolute;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .textBor{
        width: 200px;
        height: 143px;
        border: 1px dashed #B0C5D9;
        border-radius: 5px;
        position: absolute;
        box-sizing: border-box;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        color: #222222;
        line-height: 1.8;
        padding: 10px 0;
      }
      .text1{
        left:15px;
        top:-60px;
      }
      .text2{
        text-align: center;
        top: -88px;
        left: 171px;
      }
      .text3{
        left: 440px;
        top: -180px;
      }
      .text4{
        right: -40px;
        top: -180px;
      }
      .text5{
        top: -230px;
        right: 117px;
      }
    }
  }
</style>
