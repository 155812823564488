<template>
  <div class="cpgc">
    <div class="webCont">
      <div class="indexTitle">
        <p>产品构成</p>
        <span></span>
      </div>
      <div class="items">
        <div class="item wow animate__rotateInDownLeft">
          <div class="img"><img src="static/lvya/schoolFzpt/index/cpgc-img1.png" alt=""></img></div>
          <div class="text">
            <p class="t">发展规划平台</p>
            <p class="b">
              发展规划平台围绕办学目标自上而下进行目标规划、任务分解，为学校构建“目标-计划-任务-手段-监督-检查-绩效”完善的发展管理体系。
            </p>
            <div class="btn" @click="toLinkNew('/developmentPlanning')">查看详情</div>
          </div>
        </div>
        <div class="item  wow animate__fadeInUp">
          <div class="img"><img src="static/lvya/schoolFzpt/index/cpgc-img2.png" alt=""></img></div>
          <div class="text">
            <p class="t">学生成长平台</p>
            <p class="b">
              学生成长平台以兼顾学生对学科知识的掌握以及通用能力培养的方式，为学校构建可落地执行的学生成长体系。
            </p>
            <div class="btn" @click="toLinkNew('/studentGrowth')">查看详情</div>
          </div>
        </div>
        <div class="item wow animate__rotateInDownRight">
          <div class="img"><img src="static/lvya/schoolFzpt/index/cpgc-img3.png" alt=""></img></div>
          <div class="text">
            <p class="t">教师发展平台</p>
            <p class="b">
              教师发展平台围绕学生培养目标建立健全教师培养与评价体系，基于学生培养目标对教师发展持续赋能。
            </p>
            <div class="btn" @click="toLinkNew('/teacherDevelopment')">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toLinkNew(path) {
      let routeData = this.$router.resolve({ path })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang="less">
  .cpgc{
    padding-bottom: 80px;
    .items{
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      background: url(../../../../../public/static/lvya/schoolFzpt/index/cpgc-bg.png) no-repeat bottom center;
      background-size: 100%;
      padding-bottom: 150px;
      .item{
        width: 376px;
        height: 541px;
        background: url(../../../../../public/static/lvya/schoolFzpt/index/cpgc-itemBg.png) no-repeat top;
        background-size: 100%;
        .img{
          text-align: center;
          padding: 45px 0;
        }
        .text{
          .t{
            font-size: 26px;
            font-weight: 500;
            color: #222222;
            text-align: center;
            margin-bottom: 20px;
          }
          .b{
            font-size: 18px;
            font-weight: 400;
            color: #696969;
            line-height: 1.8;
            padding: 0 35px;
            text-align: justify;
            height: 130px;
          }
        }
        .btn{
          width: 172px;
          height: 44px;
          border: 1px solid #0DC579;
          border-radius: 4px;
          text-align: center;
          color: #0DC579;
          transition: all 0.5s;
          line-height: 44px;
          display: block;
          margin: 20px auto 0;
          cursor: pointer;
          &:hover{
            background: #0DC579;
            color: #fff;
            transition: all 0.5s;
          }
        }
      }
    }
  }
</style>
